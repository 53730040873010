import React from 'react'

const Footer = () => (
    <footer id="footer">
        <div className="inner footer-flex">
            <ul className="icons">
                <li><a href="https://twitter.com/jonwayne" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="https://www.instagram.com/jwayniac" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
            </ul>
            <div>
                Quickly built by <a href="https://twitter.com/TheoOnTwitch">Theo</a>
            </div>
        </div>
    </footer>
)

export default Footer
